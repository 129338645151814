<template>
  <app-page-layout :title="$t('hr.employees.employee_profile')" icon="tw-hr-cost" @close="leavePage">
    <template #content>
      <v-row>
        <v-col cols="12">
          <v-list-item>
            <v-list-item-avatar size="60" :color="imageUrl ? 'white' : 'primary'">
              <v-img v-on="on" v-if="imageUrl" :src="imageUrl" :lazy-src="imageUrl" />
              <span class="white--text text-h5" v-else> {{ employeeShortName }} </span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h5 grey--text text--darken-2 font-weight-bold">
                {{ employeeFullName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <template v-for="(field, index) in fields">
          <v-col cols="12" :key="index" class="py-1">
            <v-row>
              <v-col cols="12" sm="4">
                <span class="grey--text text--darken-2 subtitle-1 font-weight-bold">
                  {{ field.label }}
                </span>
              </v-col>
              <v-col cols="12" sm="8">
                <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
                <template v-else-if="field.chip">
                  <employee-chip v-if="field.value" :employee="field.value" />
                </template>
                <template v-else-if="field.multiChip">
                  <employee-chip
                    class="mr-1 mb-1"
                    v-for="(employee, idx) in field.value"
                    :employee="field.valueFn(employee)"
                    :key="idx"
                  />
                </template>
                <span v-else class="grey--text text--darken-1 subtitle-1">
                  {{ field.value }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <v-col cols="12">
          <span class="grey--text text--darken-2 subtitle-1 font-weight-bold">
            {{ $t("hr.employees.leaveAccruals") }}
          </span>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :items="leaveAccrualTable.items"
            :headers="leaveAccrualTable.headers"
            :loading="leaveAccrualTable.loading"
          >
            <template #item.leavePolicy="props">
              {{
                isLanguageTurkish
                  ? $helpers.get(props.item, "leavePolicy.leaveType.name")
                  : $helpers.get(props.item, "leavePolicy.leaveType.englishName") ||
                    $helpers.get(props.item, "leavePolicy.leaveType.name")
              }}
            </template>
            <template #item.leaveBalance="props">
              {{ calculateLeaveBalance(props.item) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </app-page-layout>
</template>

<script>
  import { Employee, LeaveAccruals } from "../gql";

  export default {
    name: "EmployeeDetail",
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer"),
      EmployeeChip: () => import("../components/EmployeeChip")
    },
    props: {
      id: [String, Number]
    },
    data: vm => ({
      loading: false,
      employee: null,
      leaveAccrualTable: {
        loading: false,
        items: [],
        headers: [
          {
            text: vm.$t("hr.employees.leaveType"),
            value: "leavePolicy",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.employees.accrualCount"),
            value: "leavePolicy.accrualCount",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.employees.leaveBalance"),
            value: "leaveBalance",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.employees.used"),
            value: "used",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.employees.previousBalance"),
            value: "previousBalance",
            sortable: false,
            class: ["primary--text"]
          }
        ]
      }
    }),
    computed: {
      isLanguageTurkish() {
        return this.$store.state.auth.user.language === "tr";
      },
      employeeShortName() {
        return this.employee ? this.employee.firstName.substring(0, 1) + this.employee.lastName.substring(0, 1) : "-";
      },
      employeeFullName() {
        return this.employee ? this.employee.firstName + " " + this.employee.lastName : "-";
      },
      imageUrl() {
        return this.employee ? this.employee.profile.imageUrl : null;
      },
      fields() {
        return [
          {
            label: this.$t("hr.employees.email"),
            value: this.$helpers.get(this.employee, "workEmail") || "-"
          },
          {
            label: this.$t("hr.employees.staffNumber"),
            value: this.$helpers.get(this.employee, "employment.staffNumber") || "-"
          },
          {
            label: this.$t("hr.employees.branch"),
            value: this.$helpers.get(this.employee, "employment.branch.name") || "-"
          },
          {
            label: this.$t("hr.employees.unit"),
            value: this.$helpers.get(this.employee, "employment.unit.name") || "-"
          },
          {
            label: this.$t("hr.employees.team"),
            value: this.$helpers.get(this.employee, "employment.team.name") || "-"
          },
          {
            label: this.$t("hr.employees.jobInfo"),
            value: this.$helpers.get(this.employee, "employment.jobInfo.name") || "-"
          },
          {
            label: this.$t("hr.employees.status"),
            value: this.$helpers.get(this.employee, "employment.passive")
              ? this.$t("hr.employees.passive")
              : this.$t("hr.employees.active")
          },
          {
            label: this.$t("hr.employees.hiredDate"),
            value: this.$helpers.get(this.employee, "employment.hiredDate") || "-"
          },
          {
            label: this.$t("hr.employees.birthDate"),
            value: this.$helpers.get(this.employee, "personal.birthDate") || "-"
          },
          {
            label: this.$t("hr.employees.manager"),
            value: this.$helpers.get(this.employee, "management.manager"),
            chip: true
          },
          {
            label: this.$t("hr.employees.hrManagers"),
            value: this.$helpers.get(this.employee, "management.hrManagers"),
            valueFn: item => item.manager,
            multiChip: true
          },
          {
            label: this.$t("hr.employees.leaveManagers"),
            value: this.$helpers.get(this.employee, "leaveSettings.leaveManagers"),
            valueFn: item => item.manager,
            multiChip: true
          },
          {
            label: this.$t("hr.employees.shiftManagers"),
            value: this.$helpers.get(this.employee, "timeSettings.shiftManagers"),
            valueFn: item => item.manager,
            multiChip: true
          },
          {
            label: this.$t("hr.employees.overtimeManagers"),
            value: this.$helpers.get(this.employee, "timeSettings.overtimeManagers"),
            valueFn: item => item.manager,
            multiChip: true
          }
        ];
      }
    },
    watch: {
      id: {
        handler() {
          this.fetchItemById();
          this.fetchLeaveAccruals();
        },
        immediate: true
      }
    },
    methods: {
      calculateLeaveBalance(leaveAccrual) {
        let total = leaveAccrual.previousBalance + leaveAccrual.leavePolicy.accrualCount - leaveAccrual.used;
        return Number(total.toFixed(2));
      },
      leavePage() {
        this.$emit("closed:detail");
        this.$router.replace({ name: "employees" });
      },
      fetchItemById() {
        this.loading = true;
        this.$apollo
          .query({
            query: Employee,
            variables: {
              id: this.$helpers.decodeId(this.id)
            },
            fetchPolicy: "no-cache"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee;
              this.$emit("detail-loaded:success", data.employee);
            }
          })
          .catch(console.error)
          .finally(() => (this.loading = false));
      },
      fetchLeaveAccruals() {
        this.leaveAccrualTable.loading = true;
        this.$apollo
          .query({
            query: LeaveAccruals,
            variables: {
              employee: this.$helpers.decodeId(this.id),
              onlyRequestable: true
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.leaveAccrualTable.items = data.leaveAccruals;
            }
          })
          .catch(console.error)
          .finally(() => (this.leaveAccrualTable.loading = false));
      }
    }
  };
</script>
